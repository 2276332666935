<div class="page">
    <div class="banner">
        <h1>Hello 👋🏼</h1>
        <h1>I am Louis La Grange</h1>
    </div>

    <div class="content">
        <div class="project-display">
            <img src="assets/images/proj1.JPG" alt="Project 1">
            <p>Project 1</p>
        </div>
        <div class="project-display">
            <img src="assets/images/proj2.jpg" alt="Project 2">
            <p>Project 2</p>
        </div>
        <div class="project-display">
            <img src="assets/images/proj3.jpg" alt="Project 3">
            <p>Project 3</p>
        </div>
    </div>
</div>