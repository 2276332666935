// Custom router-animation class which defines the animations between components (LJLG)
import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes,
    stagger,
    // ...
} from '@angular/animations';

const optional = { optional: true };
const animationTiming = '0.5s cubic-bezier(0.45, 0, 0.55, 1)';
// Fader animation
export const fader =
    trigger('routerAnimations', [
        // Transition will run for: (component) to/from (component)
        transition('* <=> *', [
            // End CSS styles applied when component leaves or enters
            query(':enter, :leave', [
                style({
                    position: 'fixed',
                    /* height: '101%', */
                    /* 'overflow-y': 'visible', */
                    /* width: 'calc(100% - var(--nav-width))', */
                }),
            ], { optional: true }),
            group([
                query(':enter', [
                    style({
                        opacity: 0,
                        transform: 'translateX(100%) scale(0.75)'
                    }),
                    animate(animationTiming, style({
                        opacity: 1,
                        /* 'margin-bottom': '0px', */
                        transform: 'translateX(0%) scale(1)'
                    }))
                ], optional),
                /* query(':leave', [
                    style({
                        opacity: 1,
                        transform: 'translateX(0%)'
                    }),
                    animate(animationTiming, style({
                        opacity: 0,
                        transform: 'translateX(-100%)'
                    }))
                ], optional), */
            ])
        ]),
    ]);

// Slider Animation
export const slider =
    trigger('routerAnimations', [
        transition('isLeft => *', slideTo('right')),
        transition('* => isLeft', slideTo('left')),
        transition('* => isRight', slideTo('right')),
        transition('isRight => *', slideTo('left')),

        // Five items (possible future use)
        /* transition('isLeft => *', slideTo('right')),
        transition('* => isRight', slideTo('right')),
        transition('isMiddle1 => isMiddle2', slideTo('right')),
        transition('isMiddle2 => isMiddle3', slideTo('right')),
        transition('* => isLeft', slideTo('left')),
        transition('isRight => *', slideTo('left')),
        transition('isMiddle2 => isMiddle1', slideTo('left')),
        transition('isMiddle3 => isMiddle2', slideTo('left')),
        transition('isMiddle1 => isMiddle3', slideTo('right')),
        transition('isMiddle3 => isMiddle1', slideTo('left')), */
    ]);

export const inOutAnimation =
    trigger('inOutAnimation', [
        transition('* => *', [ // each time the binding value changes
            query(':leave', [
                stagger(100, [
                    style({ transform: 'translateX(0%)', opacity: 1 }),
                    animate('0.75s cubic-bezier(0.76, 0, 0.24, 1)',
                        style({ transform: 'translateX(-100%)', opacity: 0 }))
                ])
            ], { optional: true }),
            query(':enter', [
                style({ opacity: 0 }),
                stagger(100, [
                    style({ transform: 'translateX(-100%)', opacity: 0 }),
                    animate('0.75s cubic-bezier(0.76, 0, 0.24, 1)',
                        style({ transform: 'translateX(0%)', opacity: 1 }))
                ])
            ], { optional: true })
        ])

    ]);

// Function called to apply styles dynamically for (param)
function slideTo(direction) {
    const pageSlideAnimation = '0.75s cubic-bezier(0.76, 0, 0.24, 1)';
    if (direction === 'right') {
        return [
            query(':enter, :leave', [
                style({
                    position: 'fixed',
                    width: '100%',
                })
            ], optional),
            /* query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
            ], optional), */
            group([
                query(':enter', [
                    style({ transform: 'translateX(100%)' }),
                    animate(pageSlideAnimation, style({ transform: 'translateX(0%)' }))
                ], optional),
                query(':leave', [
                    style({ transform: 'translateX(0%)' }),
                    animate(pageSlideAnimation, style({ transform: 'translateX(-100%)' }))
                ], optional),
            ])
        ];
    } else {
        return [
            query(':enter, :leave', [
                style({
                    position: 'fixed',
                    width: '100%',
                })
            ], optional),
            /*
            query(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
            ], optional), */
            group([
                query(':enter', [
                    style({ transform: 'translateX(-100%)' }),
                    animate(pageSlideAnimation, style({ transform: 'translateX(0%)' }))
                ], optional),
                query(':leave', [
                    style({ transform: 'translateX(0%)' }),
                    animate(pageSlideAnimation, style({ transform: 'translateX(100%)' }))
                ], optional),
            ])
        ];
    }
}
