import { Component, OnInit } from '@angular/core';
import { fader, slider } from './animations';
import { RouterOutlet } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fader
  ]
})
export class AppComponent implements OnInit {
  title = 'Louis la Grange';
  name = environment.application.name;
  angular = environment.application.angular;

  features: any;

  constructor(
    private meta: Meta,
    private titleService: Title) {
    this.features =
      [
        {
          name: 'About',
          description: 'About Louis la Grange',
          link: 'about'
        },
        {
          name: 'Projects',
          description: 'Recent Projects by Louis la Grange',
          link: 'projects'
        },
      ];
  }

  ngOnInit(): void {
    const loader = document.getElementById('page-loader');
    loader.classList.add('fadeloader');

    this.titleService.setTitle('Louis la Grange');
    this.meta.addTag({
      name: 'louislagrange',
      content: 'louis la grange'
    });
    this.meta.updateTag(
      {
        name: 'description',
        content: 'A portfolio website built and maintained entirely me.'
          + ' Its purpose is to showcases who I am, as well as the projects that I have worked on recently.'
          + ' Built with Angular.'
      });
  }

  // Linked to routerOutlet in HTML, will run when routerLink is called/clicked (LJLG)
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
