import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {

  DOB = new Date('1998-03-02');
  age;

  netbeans = 2014;
  androidStudio = 2017;
  msOffice = 2005;
  windows = 2005;
  linux = 2019;
  photoshop = 2015;
  gimp = 2019;
  git = 2017;
  vsCode = 2017;
  firefox = 2017;
  chrome = 2013;
  phpMyAdmin = 2018;
  trello = 2019;

  constructor() {
    const timeDiff = Math.abs(Date.now() - this.DOB.getTime());
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

    this.netbeans = new Date().getFullYear() - this.netbeans;
    this.androidStudio = new Date().getFullYear() - this.androidStudio;
    this.msOffice = new Date().getFullYear() - this.msOffice;
    this.windows = new Date().getFullYear() - this.windows;
    this.linux = new Date().getFullYear() - this.linux;
    this.photoshop = new Date().getFullYear() - this.photoshop;
    this.gimp = new Date().getFullYear() - this.gimp;
    this.git = new Date().getFullYear() - this.git;
    this.vsCode = new Date().getFullYear() - this.vsCode;
    this.firefox = new Date().getFullYear() - this.firefox;
    this.chrome = new Date().getFullYear() - this.chrome;
    this.phpMyAdmin = new Date().getFullYear() - this.phpMyAdmin;
    this.trello = new Date().getFullYear() - this.trello;
  }

  ngOnInit(): void {
  }

}
