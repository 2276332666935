import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactComponent } from './contact/contact.component';
import { CvComponent } from './cv/cv.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';

const routes: Routes = [
  { path: '', redirectTo: 'about', pathMatch: 'full' },
  { path: 'about', component: AboutComponent, data: { animation: '1' } },
  /* { path: 'cv', component: CvComponent, data: { animation: '2' } }, */
  { path: 'projects', component: ProjectsComponent, data: { animation: '3' } },
  /* { path: 'contact', component: ContactComponent, data: { animation: '4' } }, */
  { path: '**', redirectTo: 'about' },
  /* { path: '', redirectTo: '', pathMatch: 'full' }, */
  /* { path: '', component: ComingSoonComponent, data: { animation: '1' } }, */
  /* { path: '**', redirectTo: '' }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
