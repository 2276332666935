<!-- PAGE CODE -->
<div class="page">
    <div class="content">
        <h1>CONTACT</h1>
        <form id="form" name="form" action="/home.html" method="GET">
            <label>Full Name</label>
            <input class="input" id="txt_name" type="text" placeholder="John Kane">

            <label>Email Address</label>
            <input class="input" id="txt_email" type="email" placeholder="test@mail.net">

            <label>Mobile Number</label>
            <input class="input" id="txt_number" type="text" placeholder="0712345678">

            <label>Country</label>
            <select class="input" id="cmb_country">
                <option value="Australia">Australia</option>
                <option selected value="South Africa">South Africa</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
            </select>

            <label>Message</label>
            <textarea class="input" id="txa_message" type="" placeholder="Enter your message..."></textarea>

            <button class="input submit" value="submit">Submit</button>
        </form>
    </div>
</div>