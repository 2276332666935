import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

  scrollToTop(){
    const element = document.documentElement;
    if (element) {
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

}
