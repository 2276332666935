<!-- PAGE CODE -->
<div class="page">
    <div class="banner">
        <h1>Hello 👋🏼</h1>
        <h1>I am Louis la Grange</h1>
        <label class="chevron-down">
            <a href="about#content">
                <svg aria-hidden="true" focusable="false" data-icon="chevron-down" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <g class="icon-group">
                        <path fill="currentColor"
                            d="M224.1 284.64l-56.89 56.78-154-154.31a24 24 0 0 1 0-33.94l22.65-22.7a23.93 23.93 0 0 1 33.84 0z"
                            class="icon-secondary"></path>
                        <path fill="currentColor"
                            d="M435 187.15L241 381.48a23.94 23.94 0 0 1-33.84 0l-40-40 211.34-211a23.93 23.93 0 0 1 33.84 0L435 153.21a24 24 0 0 1 0 33.94z"
                            class="icon-primary"></path>
                    </g>
                </svg>
            </a>
        </label>
    </div>

    <div class="banner-spacer">
        
    </div>

    <div class="content" id="content">
        <div class="section">
            <div class="title">
                <svg aria-hidden="true" focusable="false" data-icon="info" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 512">
                    <path
                        d="M208 368.667V208c0-15.495-7.38-29.299-18.811-38.081C210.442 152.296 224 125.701 224 96c0-52.935-43.065-96-96-96S32 43.065 32 96c0 24.564 9.274 47.004 24.504 64H56c-26.467 0-48 21.533-48 48v48c0 23.742 17.327 43.514 40 47.333v65.333C25.327 372.486 8 392.258 8 416v48c0 26.467 21.533 48 48 48h144c26.467 0 48-21.533 48-48v-48c0-23.742-17.327-43.514-40-47.333zM128 32c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64 28.654-64 64-64zm88 432c0 8.837-7.163 16-16 16H56c-8.837 0-16-7.163-16-16v-48c0-8.837 7.163-16 16-16h24V272H56c-8.837 0-16-7.163-16-16v-48c0-8.837 7.163-16 16-16h104c8.837 0 16 7.163 16 16v192h24c8.837 0 16 7.163 16 16v48z"
                        class="icon-primary"></path>
                </svg>
            </div>
            <div class="section-content">
                <div class="heading">
                    <span>About</span>
                </div>
                <p>
                    I am a {{ age }} year old Software Engineer based in South Africa 🇿🇦 with a strong educational
                    background and demonstrated history of working in the Information Technology & Systems industry. 🛠️
                </p>
                <br>
                <p>
                    As a BSc IT graduate, from PIHE (Pearson Institute of Higher Education) 🎓, I am currently seeking
                    exciting and engaging new job opportunities in order to expand my knowledge and international
                    experience. 🌍
                </p>
                <br>
                <p>
                    During these last four years of my studies 📚, I have been helping and serving real clients -
                    as well as myself - by building IT tools with the use of front-end 🖥️, back-end 📋 and database
                    development 💾 (full-stack) principles.
                </p>
                <br>
                <p>
                    I love when things work as they are intended to and strive to supersede this goal in all of the
                    projects that I am a part of. I also love learning about new technologies and tools that can help me
                    complete my work more efficiently. 💡
                </p>
                <br>
                <p>
                    My ongoing speciality is currently focused towards Web and Android development as well as how to
                    integrate the two using Cloud-based technologies. I am also very passionate about helping people and
                    teaching what I know to colleagues and friends at campus and at home. 👨‍🏫
                </p>
            </div>
        </div>
        <hr>
        <div class="section">
            <div class="title">
                <svg aria-hidden="true" focusable="false" data-icon="lightbulb" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z"
                        class="icon-primary"></path>
                </svg>
            </div>
            <div class="section-content">
                <div class="heading">
                    <span>Skills</span>
                </div>
                <ul>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Java (Plain/Android)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Angular 2+ (CLI)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>HTML</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>CSS/SCSS</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>TypeScript</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>SQL (MS Server/MySQL)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>NoSQL (Firestore)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Firebase</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Agile Software Development</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>JavaScript</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>GCP (Google Gloud Platform)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>PHP</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="1"></app-star>
                        <span>Bootstrap</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="1"></app-star>
                        <span>XML</span>
                    </li>
                </ul>
                <!-- <p>
                    Demonstrated experience in:
                    Java, Android Development, TypeScript (JavaScript), PHP, Web development, SQL, no-SQL relational
                    Database
                    development.
                </p> -->
            </div>
        </div>
        <hr>
        <div class="section">
            <div class="title">
                <svg aria-hidden="true" focusable="false" data-icon="tools" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M224 96.1v48.8l29.7 29.7c-6.8-34.8 3.5-70.3 28.5-95.3 20.3-20.3 47.2-31.2 75-31.2h1.2L301 105.3l15.1 90.6 90.6 15.1 57.3-57.3c.3 28.3-10.6 55.5-31.2 76.1-9.3 9.3-20.2 16.4-31.8 21.6 1.8 1.6 3.9 2.9 5.6 4.6l30.7 30.7c10.5-6.3 20.5-13.9 29.4-22.9 38.1-38.1 53.7-94.3 40.7-146.6C504.4 105 495 95.4 483 92c-12.2-3.4-25.2.1-34 9l-58.7 58.6-32.4-5.4-5.4-32.4 58.6-58.6c8.9-8.9 12.3-21.9 8.9-34-3.3-12.1-13-21.5-25.2-24.5-53.2-13.2-107.9 2-146.6 40.6C238 55.5 229.7 67 222.9 79.2l1.1.8v16.1zM106 454c-12.8 12.8-35.3 12.8-48.1 0-6.4-6.4-10-15-10-24 0-9.1 3.5-17.6 10-24l134.4-134.4-33.9-33.9L24 372C8.5 387.5 0 408.1 0 430s8.5 42.5 24 58 36.1 24 58 24 42.5-8.5 58-24l100.9-100.9c-9.7-15.8-15.2-33.8-15.7-52.1L106 454zm395.1-58.3L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7z"
                        class="icon-primary"></path>
                </svg>
            </div>
            <div class="section-content">
                <div class="heading">
                    <span>Tools</span>
                </div>
                <ul>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>NetBeans ({{netbeans}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Android Studio ({{androidStudio}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>MS Office ({{msOffice}}+ years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Windows ({{windows}}+ years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>VS Code ({{vsCode}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Firefox ({{firefox}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Trello ({{trello}} year)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>Linux ({{linux}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>Photoshop ({{photoshop}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>Git/BitBucket ({{git}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>phpMyAdmin ({{phpMyAdmin}} years)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="1"></app-star>
                        <span>GIMP ({{gimp}} year)</span>
                    </li>
                    <!-- <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Davinci Resolve (2 years)</span>
                    </li> -->
                </ul>
                <!--  <p>
                    Demonstrated experience in:
                    Java, Android Development, TypeScript (JavaScript), PHP, Web development, SQL, no-SQL relational
                    Database
                    development.
                </p> -->
            </div>
        </div>
        <hr>
        <div class="section">
            <div class="title">
                <svg aria-hidden="true" focusable="false" data-icon="language" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M160.3 203.8h-.5s-4.3 20.9-7.8 33l-11 37.3h37.9l-10.7-37.3c-3.6-12.1-7.9-33-7.9-33zM616 96H24c-13.3 0-24 10.7-24 24v272c0 13.3 10.7 24 24 24h592c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24zM233.2 352h-22.6a12 12 0 0 1-11.5-8.6l-9.3-31.7h-59.9l-9.1 31.6c-1.5 5.1-6.2 8.7-11.5 8.7H86.8c-8.2 0-14-8.1-11.4-15.9l57.1-168c1.7-4.9 6.2-8.1 11.4-8.1h32.2c5.1 0 9.7 3.3 11.4 8.1l57.1 168c2.6 7.8-3.2 15.9-11.4 15.9zM600 376H320V136h280zM372 228h110.8c-6.3 12.8-15.1 25.9-25.9 38.5-6.6-7.8-12.8-15.8-18.3-24-3.5-5.3-10.6-6.9-16.1-3.6l-13.7 8.2c-5.9 3.5-7.6 11.3-3.8 17 6.5 9.7 14.4 20.1 23.5 30.6-9 7.7-18.6 14.8-28.7 21.2-5.4 3.4-7.1 10.5-3.9 16l7.9 13.9c3.4 5.9 11 7.9 16.8 4.2 12.5-7.9 24.6-17 36-26.8 10.7 9.6 22.3 18.6 34.6 26.6 5.8 3.7 13.6 1.9 17-4.1l8-13.9c3.1-5.5 1.5-12.5-3.8-16-9.2-6-18.4-13.1-27.2-20.9 1.5-1.7 2.9-3.3 4.3-5 17.1-20.6 29.6-41.7 36.8-62H540c6.6 0 12-5.4 12-12v-16c0-6.6-5.4-12-12-12h-64v-16c0-6.6-5.4-12-12-12h-16c-6.6 0-12 5.4-12 12v16h-64c-6.6 0-12 5.4-12 12v16c0 6.7 5.4 12.1 12 12.1z"
                        class="icon-primary"></path>
                </svg>
            </div>
            <div class="section-content">
                <div class="heading">
                    <span>Languages</span>
                </div>
                <ul>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>English (Native)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="3"></app-star>
                        <span>Afrikaans (Native)</span>
                    </li>
                    <li>
                        <app-star class="star-icon" [rating]="2"></app-star>
                        <span>Portuguese (Intermediate)</span>
                    </li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <!-- <p>
                    Demonstrated experience in:
                    Java, Android Development, TypeScript (JavaScript), PHP, Web development, SQL, no-SQL relational
                    Database
                    development.
                </p> -->
            </div>
        </div>
        <hr>
    </div>
</div>