<!-- FOOTER CODE -->
<footer>
    <label class="bold-text">
        <svg aria-hidden="true" focusable="false" data-icon="code" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512">
            <path fill="currentColor"
                d="M234.8 511.7L196 500.4c-4.2-1.2-6.7-5.7-5.5-9.9L331.3 5.8c1.2-4.2 5.7-6.7 9.9-5.5L380 11.6c4.2 1.2 6.7 5.7 5.5 9.9L244.7 506.2c-1.2 4.3-5.6 6.7-9.9 5.5zm-83.2-121.1l27.2-29c3.1-3.3 2.8-8.5-.5-11.5L72.2 256l106.1-94.1c3.4-3 3.6-8.2.5-11.5l-27.2-29c-3-3.2-8.1-3.4-11.3-.4L2.5 250.2c-3.4 3.2-3.4 8.5 0 11.7L140.3 391c3.2 3 8.2 2.8 11.3-.4zm284.1.4l137.7-129.1c3.4-3.2 3.4-8.5 0-11.7L435.7 121c-3.2-3-8.3-2.9-11.3.4l-27.2 29c-3.1 3.3-2.8 8.5.5 11.5L503.8 256l-106.1 94.1c-3.4 3-3.6 8.2-.5 11.5l27.2 29c3.1 3.2 8.1 3.4 11.3.4z"
                class="icon-primary">
            </path>
        </svg>
        with ❤️ by Louis la Grange &copy; {{currentYear}}
    </label>
    <label class="chevron-up">
        <a (click)="scrollToTop()">
            <svg aria-hidden="true" focusable="false" data-icon="chevron-up" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <g class="icon-group">
                    <path fill="currentColor"
                        d="M69.66 381.49a23.93 23.93 0 0 1-33.84 0l-22.65-22.7a24 24 0 0 1 0-33.94l154.04-154.31 56.89 56.78z"
                        class="icon-secondary"></path>
                    <path fill="currentColor"
                        d="M435 358.75l-22.65 22.7a23.92 23.92 0 0 1-33.84 0l-211.34-211 40-40a23.93 23.93 0 0 1 33.84 0L435 324.81a24 24 0 0 1 0 33.94z"
                        class="icon-primary"></path>
                </g>
            </svg>
        </a>
    </label>
</footer>