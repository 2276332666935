<div class="page">
    <div class="banner">
        <h1>Hello,</h1>
        <h1>I am Louis La Grange</h1>
    </div>

    <div class="content">
        <p>
            Experienced Software Developer & current Managing IT Director with a demonstrated history of working in the
            information technology and services industry.
            Demonstrated experience in:
            Java, Android Development, TypeScript (JavaScript), PHP, Web development, SQL, no-SQL relational Database
            development.
            Skilled business development professional studying towards a BSc IT degree from Pearson Institute.
            Focusing on Information Technology as well as business management.
        </p>
        <br>
        <p>
            Sed at massa quis orci varius suscipit. Aenean lacus libero, molestie non tristique vel, consectetur eget
            ex. Vestibulum rhoncus efficitur nisl sed blandit. Aliquam mattis justo non neque tristique luctus. Fusce
            pharetra sollicitudin tempor. Sed suscipit quam ante, vulputate viverra lorem gravida sed. Cras id felis
            mauris. Nam interdum diam odio, ut tempor metus congue eget. Donec at malesuada nisi, consequat venenatis
            ipsum.
        </p>
    </div>
</div>