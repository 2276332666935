<!-- PAGE CODE -->
<div class="page">
    <h1>Recent Projects</h1>

    <div class="content">
        <div class="title">
            <span>ViewProtect Assistant (Android, Web, Desktop/Java)</span>
        </div>
        <div class="project-display">
            <div class="project-image">
                <img src="assets/images/viewprotect-web-demo.webp" alt="Project 1">
            </div>
            <div class="project-text">
                <span class="heading">About ViewProtect</span>
                <p>
                    They are: "South Africa’s leader in clear burglar bars design and manufacturing." as quoted on their
                    website.

                    "With internationally patented Smart Bar technology, ViewProtect clear burglar bars offer the
                    consumer an integrated active alarm sensor and physical transparent barrier for 24/7 alarm monitored
                    protection."
                </p>
                <br>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://viewprotect.co.za/"
                        class="external-link">ViewProtect's
                        Website
                        <svg aria-hidden="true" focusable="false" data-icon="external-link" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z">
                            </path>
                        </svg>
                    </a>
                </p>
                <br>
            </div>
        </div>

        <div class="project-display">
            <div class="project-image">
                <div class="multi-image">
                    <div class="column">
                        <img src="assets/images/viewprotect-assistant-homescreen.webp" alt="Homescreen">
                    </div>
                    <div class="column">
                        <img src="assets/images/viewprotect-assistant-newquote.webp" alt="New Quote">
                    </div>
                    <div class="column">
                        <img src="assets/images/viewprotect-assistant-pdfquote.webp" alt="PDF Quote">
                    </div>
                </div>
            </div>
            <div class="project-text">
                <span class="heading">Development for ViewProtect</span>
                <p>
                    The systems that I helped develop for ViewProtect includes client-facing and business-facing
                    applications aiming to generate PDF quotes for the customer on the spot. This platform reduces the
                    time taken between quote generation and factory production of the products, and offers a central &
                    standardized system to manage their operations.
                </p>
                <br>
                <p>
                    Firebase was used to: implement user authentication; store user, company, and product information;
                    and to store quote data files generated by the mobile application. This made way for a factory
                    administrator to instantly see and process new orders coming in on the desktop application, and also
                    allowed product and material prices to be updated instantly from a web-based DBMS.
                </p>
                <br>
            </div>
        </div>
        <hr>
    </div>

    <div class="content">
        <div class="title">
            <span>Blinds4U (Android, Desktop/Java)</span>
        </div>
        <div class="project-display">
            <div class="project-image">
                <div class="multi-image">
                    <div class="column">
                        <img src="assets/images/b4u-new-quote.png" alt="New Quote">
                    </div>
                    <div class="column">
                        <img src="assets/images/b4u-quotes.png" alt="Homescreen">
                    </div>
                </div>
            </div>
            <div class="project-text">
                <span class="heading">About Blinds4U</span>
                <p>
                    They are: "One of the leading blind supplier companies in Gauteng, Mpumalanga & Mozambique with a
                    reputation of quality prices combined with exquisite service." as quoted on their website.
                </p>
                <br>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.blinds4u.co.za/"
                        class="external-link">Blinds4U's
                        Website
                        <svg aria-hidden="true" focusable="false" data-icon="external-link" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z">
                            </path>
                        </svg>
                    </a>
                </p>
                <br>
            </div>
        </div>

        <div class="project-display">
            <div class="project-image">
                <div class="multi-image">
                    <div class="column">
                        <img src="assets/images/b4u-add-window.png" alt="Add Window">
                    </div>
                    <div class="column">
                        <img src="assets/images/b4u-manage-windows.png" alt="Manage Windows">
                    </div>
                </div>
            </div>
            <div class="project-text">
                <span class="heading">Development for Blinds4U</span>
                <p>
                    The system that I helped develop for Blinds4U includes a business-facing application that aims
                    to centralize and speed up the quoting process, used by their blinds installers. The system consists
                    of an Android-based application for data capturing and a Java-based desktop application to retrieve
                    all the uploaded quotes. This facilitates real-time quote management in a centralized environment
                    with flexible quote templates.
                </p>
                <br>

                <p>
                    The Cloud-based system makes use of RESTful calls to and from the Google Sheets API,
                    facilitated by Firebase Cloud functions. This offers two major advantages:
                    <br>1. This allows the Google Sheets quote template to be updated to any layout that the Blinds4U
                    administration team desires (without developer intervention) to be exported as a .xlsx document.
                    <br>2. This drastically reduces data usage from the data-capturing devices, as they only need to
                    send
                    small JSON files and leave the quote generation to the servers.
                </p>
                <br>
            </div>
        </div>
        <hr>
    </div>

    <div class="content">
        <div class="title">
            <span>Small-scale E-commerce Website (PHP, MySQL, HTML/CSS)</span>
        </div>
        <div class="project-display">
            <div class="project-image">
                <img src="assets/images/cake-inc-demo.webp" alt="Project 1">
            </div>
            <div class="project-text">
                <span class="heading">About the Project</span>
                <p>
                    This project was made in the final year of my degree to demonstrate what I learned from a
                    subject called ITEC301 (Internet Programming and E-Commerce). I was expected to develop a database
                    driven, responsive e-commerce website based on the requirements gathered from a client using: PHP,
                    MySQL, HTML, CSS and JavaScript.
                </p>
                <br>
                <p>
                    No external libraries were utilised to develop the system. Styles, animations, and layouts were made
                    using only CSS, HTML, and JavaScript. Furthermore, the use of AJAX queries was incorporated wherever
                    possible, to make the pages more responsive. 
                    <br>Note: The project demonstrated is proof of concept.
                </p>
                <br>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/BruceLeeu/ITEC301-Project"
                        class="external-link">Source Code
                        <svg aria-hidden="true" focusable="false" data-icon="external-link" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z">
                            </path>
                        </svg>
                    </a>
                </p>
                <br>
            </div>
        </div>
        <hr>
    </div>
</div>