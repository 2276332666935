import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  overlayMenuVisible = false;

  constructor() { }

  ngOnDestroy(): void {
    /* window.removeEventListener('scroll', this.scroll, true); */
  }

  ngOnInit(): void {
    /* window.addEventListener('scroll', this.scroll, true); */
  }

  toggleMenu() {
    if (this.overlayMenuVisible) {
      this.overlayMenuVisible = false;
    } else {
      this.overlayMenuVisible = !this.overlayMenuVisible;
    }
  }

  scrollToTop(){
    document.documentElement.scrollTop = 0;
  }
}
